import ReactJsonViewCompare from 'react-json-view-compare';
import PDFIcon from "./components/Shared/icons/pdf-solid";
import ImageIcon from "./components/Shared/icons/image-solid";
import DocIcon from "./components/Shared/icons/doc-solid";
import XMLIcon from "./components/Shared/icons/xml-solid";

const formatAreaCollection = (data) => {
    return data.map(marker => {
        const {id, area} = marker;
        return {
            "type": "Feature",
            "properties": {
                "name": id,
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [[area]]
            }
        }
    })
}

const handleFileRender = ({extension, data}) => {
    switch (true) {
        case ['pdf', 'xlsx', 'xls', 'csv'].includes(extension):
            return <embed width="100%" className='h-100' src={data}/>
        case ['json'].includes(extension):
            return <ReactJsonViewCompare oldData={data} newData={data}/>
        case ['png', 'jpeg', 'jpg', 'gif', 'webp'].includes(extension):
            return <img alt="Render Images" width="100%" src={data}/>
        default:
            return <></>
    }
}

const handleIcons = (type) => {
    switch (type) {
        case 'pdf':
            return PDFIcon;
        case 'jpg':
        case 'jpeg':
        case 'png':
            return ImageIcon;
        case 'doc':
            return DocIcon;
        case 'xml':
        case 'xls':
        case 'xlsx':
            return XMLIcon;
        default:
            return DocIcon;

    }
}

function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const defaultEventList = [
    {
        name: "Owner Validated",
        key: "Owner Validated",
        type: "user",
        icon: ""
    },
    {
        name: "Land Registered",
        key: "Land Registered",
        type: "plot",
        icon: ""
    },
    {
        name: "Plot Satellite Photo Taken",
        key: "Plot Satellite Photo Taken",
        type: "plot",
        icon: ""
    },
    {
        name: "Field Registered",
        key: "Field Registered",
        type: "field",
        icon: ""
    },
    {
        name: "Field Satellite Photo Taken",
        key: "Field Satellite Photo Taken",
        type: "field",
        icon: ""
    },
    {
        name: "Field Surveyed",
        key: "Field Surveyed",
        type: "field",
        icon: ""
    },
    {
        name: "Sample Taken",
        key: "Sample Taken",
        type: "field",
        icon: ""
    },
    {
        name: "Lab Result Received",
        key: "Lab Result Received",
        type: "field",
        icon: ""
    },
    {
        name: "Field Satellite Photo Taken",
        key: "Field Satellite Photo Taken 1",
        type: "field",
        icon: ""
    },
    {
        name: "Tokens Issued",
        key: "Tokens Issued",
        type: "field",
        icon: ""
    },
    {
        name: "Field Satellite Photo Taken",
        key: "Field Satellite Photo Taken 2",
        type: "field",
        icon: ""
    }

];

export {
    formatAreaCollection,
    handleFileRender,
    handleIcons,
    formatBytes,
    defaultEventList
}