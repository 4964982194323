import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { DataContext } from '../../context/DataContext';
import MapView from './../../components/Map/index';
import { formatAreaCollection } from './../../utils';
import PlotMarkerPopup from './../../components/Map/elements/plot-popup'
import MapMarker from './../../components/Map/elements/marker'
import Forecast from './../../components/Chart/forecast';

import './theme.css';

const Dashboard = () => {

    const { axios } = useContext(GeneralContext);
    const { mapDefaultLatitude, mapDefaultLongitude, mapDefaultStyle } = useContext(DataContext);

    const [mapElements, setMapElements] = useState({ markers: [], areas: null });
    const [viewport, setViewport] = useState({ latitude: mapDefaultLatitude, longitude: mapDefaultLongitude, zoom: 13 });
    const [markerDetails, setMarkerDetails] = useState(null);

    const getPlotLocations = async () => {

        const { error: plotsListingError, data: { data: plotsData } } = await axios.get(`/plot?page=1&items=1000`);
        const uniquePlotData = plotsData.reduce((plots, plot) => {
            const { txid, keys, data: { json: { id, tokens } } } = plot;
            return { ...plots, [id]: { txid, keys } }
        }, {})
        const { error, data: { data } } = await axios.get(`/location/transactions-by-key/plot?page=1&items=1000`);
        const markers = data.map(({ data: { json: { lat, lon, id, area } } }) => {
            const fid = id.split('-').pop();
            const [ownerKey] = uniquePlotData[fid].keys.filter(key => key.startsWith('owner-'));
            const txid = uniquePlotData[fid].txid;
            const owner = ownerKey ? ownerKey.split('-').pop() : null;
            return {
                area,
                lat,
                lon,
                id,
                type: 'marker',
                icon: "plot",
                tokens: 60000000,
                className: "plot",
                stream: "plot",
                owner,
                txid
            }
        });
        setMapElements({ markers, areas: { "type": "FeatureCollection", features: formatAreaCollection(markers) } })
    }

    useEffect(() => {
        const fetchPlotLocationData = async () => {
            await getPlotLocations();
        }
        fetchPlotLocationData().catch(console.error);
    }, [])

    const rnmin = (min, max) => (Math.random() * (max - min) + min);
    const rn = (max) => (Math.floor(Math.random() * max));

    useEffect(() => {
    }, [mapElements])

    const forecast = [
        {
            key: '2019',
            Undeveloped: rnmin(50,200).toFixed(0),
            Developed: rnmin(500,1000).toFixed(0),
        },
        {
            key: '2020',
            Undeveloped: rnmin(50,200).toFixed(0),
            Developed: rnmin(500,1000).toFixed(0),
        },
        {
            key: '2021',
            Undeveloped: rnmin(50,200).toFixed(0),
            Developed: rnmin(500,1000).toFixed(0),
        },
        {
            key: '2022',
            Undeveloped: rnmin(50,200).toFixed(0),
            Developed: rnmin(500,1000).toFixed(0),
        },
        {
            key: '2023',
            Undeveloped: rnmin(50,200).toFixed(0),
            Developed: rnmin(500,1000).toFixed(0),
        }
    ];

    const MarkerList = () => {
        return mapElements.markers ?
            mapElements.markers.map((marker, index) => {
                return (
                    <div key={`markers-list-${index}`}>
                        <PlotMarkerPopup marker={marker} markerDetails={markerDetails} setMarkerDetails={setMarkerDetails} />
                        <MapMarker marker={marker} setViewport={setViewport} markerDetails={markerDetails} setMarkerDetails={setMarkerDetails} />
                    </div>
                );
            }) : <></>;
    }

    return (
        <>
            <MapView
                viewport={viewport}
                setViewport={setViewport}
                mapElements={mapElements}
                mapType={mapDefaultStyle}
            >
                <MarkerList />
            </MapView >
            <div className="pabr text-center">
                <div className='row pt-2 pb-2 pl-4 pr-4'>
                    <div className='col-12 m-2'>
                        <Forecast labels={[
                            { name: "Developed", color: "#00D1FF" },
                            { name: "Undeveloped", color: "#6B46FF" }
                        ]}
                          description={"44,000,000 MT Co2"} title="Total Proven Reserves" data={forecast} className="chart-overlay" key1="Undeveloped" key2="Developed" />
                    </div>
                </div>
            </div>
        </>
    )

}

export default Dashboard;