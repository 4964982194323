import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import StatCards from './../../components/Card/stat-cards';
import WidgetCard from './../../components/Card/widget-card';
import Forecast from './../../components/Chart/forecast';
import DeviceSessions from './../../components/Chart/device-sessions';
import Acquisition from './../../components/Chart/acquisition';
import QuickAccess from './../../components/Files/custom/quick-access';
import TransactionHistoryContainer from '../../components/Shared/TransactionHistoryContainer';
import HistoryStory from '../../components/HistoryStory';

import './theme.css';

const SinglePlotDashboard = () => {

    const { state } = useLocation();
    const navigate = useNavigate();
    const { axios } = useContext(GeneralContext);

    const [data, setData] = useState(null);
    const [documents, setDocuments] = useState([])
    const [eventDocuments, setEventDocuments] = useState(null);
    const [plotData, setPlotData] = useState(null);
    const [plotEvents, setPlotEvents] = useState(null);
    const [plotId, setPlotId] = useState(state ? state.plotId : null);
    const [plotOwner, setPlotOwner] = useState(state ? state.owner : null);

    const getPlotEvents = async () => {
        if(!plotId || !plotOwner) return;
        let formatteduserError = {};
        try {
            const { error: userError, data: { data: userEvents } } = await axios.get(`/event/transactions-by-key/${plotOwner}?page=1&items=1000`);
            formatteduserError = userEvents.reduce((acc, eventData) => {
                const { txid, timereceived, keys, data: { json: details } } = eventData;
                const { name } = details;
                return { ...acc, [name]: { txid, timereceived, data: { ...details } } };
            }, {})

        } catch (error) {

        }

        let formattedPlotEvent = {};
        try {
            const { error: plotError, data: { data: plotEvent } } = await axios.get(`/event/transactions-by-key/${plotId}?page=1&items=1000`);
            formattedPlotEvent = plotEvent.reduce((acc, eventData) => {
                const { txid, timereceived, keys, data: { json: details } } = eventData;
                const { name } = details;
                return { ...acc, [name]: { txid, timereceived, data: { ...details } } };
            }, {})
        } catch (error) {

        }

        let formattedEventDocuments = {};
        try {
          const documentSources = {
            "plot": plotId,
            "user": plotOwner
          };
          for(const source in documentSources) {
            if(!documentSources[source]) continue;
            const { error, data: { data: documentsData } } = await axios.get(`/${source}-document-metadata/transactions-by-key/${documentSources[source]}?page=1&items=1000`);

            formattedEventDocuments = {
              ...formattedEventDocuments,
              ...documentsData.reduce((acc, documentData) => {
                const { txid, timereceived, keys, data: { json: details } } = documentData;
                const { document_id } = details;
                return { ...acc, [document_id]: { source, txid, timereceived, data: { ...details } } };
              }, {})
            };
          }
          setEventDocuments(formattedEventDocuments);
        } catch (error) {
          // Handle error
        }

        setPlotEvents({ ...formatteduserError, ...formattedPlotEvent });
    }

    const rnmin = (min, max) => (Math.random() * (max - min) + min);
    const rn = (max) => (Math.floor(Math.random() * max));

    const forecast = [
        {
            key: '2019',
            Target: rnmin(100, 1000),
            Actual: rnmin(1100, 5000),
        },
        {
            key: '2020',
            Target: rnmin(100, 1000),
            Actual: rnmin(1100, 5000),
        },
        {
            key: '2021',
            Target: rnmin(100, 1000),
            Actual: rnmin(1100, 5000),
        },
        {
            key: '2022',
            Target: rnmin(100, 1000),
            Actual: rnmin(1100, 5000),
        },
        {
            key: '2023',
            Target: rnmin(100, 1000),
            Actual: rnmin(1100, 5000),
        }
    ];

    const acquisition = [
        {
            day: 'Jan',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Feb',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Mar',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Apr',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'May',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Jun',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Jul',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Aug',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Sep',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Oct',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Nov',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
        {
            day: 'Dec',
            tokens: rnmin(10, 90).toFixed(0),
            wells: rnmin(10, 30).toFixed(0),
        },
    ];

    const deviceSessions = [
        {
            day: 'Mon',
            issued: rnmin(100, 1200).toFixed(0),
        },
        {
            day: 'Tue',
            issued: rnmin(100, 1200).toFixed(0),
        },
        {
            day: 'Thu',
            issued: rnmin(100, 1200).toFixed(0),
        },
        {
            day: 'Wed',
            issued: rnmin(100, 1200).toFixed(0),
        },
        {
            day: 'Fri',
            issued: rnmin(100, 1200).toFixed(0),
        },
        {
            day: 'Sun',
            issued: rnmin(100, 1200).toFixed(0),
        },
    ];

    const chartData = [
        {
            day: 'Sunday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Monday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Tuesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Wednesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Thursday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Friday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Saturday',
            sale: rn(4000),
            cost: rn(4000),
        },
    ];

    const chartData1 = [
        {
            day: 'Sunday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Monday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Tuesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Wednesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Thursday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Friday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Saturday',
            sale: rn(4000),
            cost: rn(4000),
        },
    ];

    const chartData2 = [
        {
            day: 'Sunday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Monday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Tuesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Wednesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Thursday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Friday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Saturday',
            sale: rn(4000),
            cost: rn(4000),
        },
    ];

    const analyticsStatData = [
        {
            id: '1',
            title: 'Frozen Plots',
            metric: '100 / 1,000',
            info: 'Frozen plots of total plots in the system.',
            increased: true,
            decreased: false,
            fill: '#015DE1',
            chart: chartData,
        },
        {
            id: '2',
            title: 'Tokens Issued',
            metric: '12,000,000',
            info: 'Total tokens in the system.',
            increased: false,
            decreased: true,
            fill: '#B92E5D',
            chart: chartData1,
        },
        {
            id: '3',
            title: 'CO2',
            metric: '44,000,000 MT',
            info: 'Metric Tonnes of CO2 emissions avoided.',
            increased: true,
            decreased: false,
            fill: '#048848',
            chart: chartData2,
        }
    ];
    const getDocuments = async () => {
        const { error, data: { data } } = await axios.get(`/plot-document-metadata/transactions-by-key/${plotId}?page=1&items=10`);
        setDocuments(data);
    }

    const getPlotLocations = async () => {
        const { data: { data } } = await axios.get(`/location/transactions-by-key/${plotId}?page=1&items=1000`);
        setData(data)
    }

    const getPlot = async () => {
        const { data: { data } } = await axios.get(`/plot/transactions-by-key/${plotId}?page=1&items=1000`);
        if(data.length && data[0]){
          const plotOwner = data[0].keys.reverse().find(key => key.startsWith('owner-')).split('-').pop();
          if(plotOwner) setPlotOwner(plotOwner);
        }
        setPlotData(data[0])
    }

    useEffect(() => {
        if (plotId === null) {
            navigate('/');
        } else {
            const fetchPlotDocuments = async () => {
                await getDocuments();
            }
            fetchPlotDocuments().catch(console.error);

            const fetchPlotLocationsData = async () => {
                await getPlotLocations();
            }
            fetchPlotLocationsData().catch(console.error);

            const fetchPlot = async () => {
                await getPlot();
            }
            fetchPlot().catch(console.error);
        }
    }, [])

    useEffect(() => { }, [plotData, data, documents, plotOwner])

    useEffect(() => {
        const fetchPlotEventsData = async () => {
            await getPlotEvents();
        }
        fetchPlotEventsData().catch(console.error);
    }, [plotId, plotOwner]);

    return (
        <div id="plot">
            <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3'}>
                <div className="mt-4 grid grid-cols-3 justify-around gap-6 @sm:py-3 @md:mt-3 @xl:mt-4 @7xl:gap-8">
                    <StatCards data={analyticsStatData}/>
                </div>
                <div className="mt-4 grid grid-cols-3 justify-around gap-6 @sm:py-3 @md:mt-3 @xl:mt-4 @7xl:gap-8">
                    <div className='text-center'>
                        <Forecast labels={[{name: "Actual", color: "#00D1FF"}, {name: "Target", color: "#6B46FF"}]}
                                  description={"100 Plots"} title="Evolution vs Forecast" data={forecast} key1="Actual"
                                  key2="Target"
                                  className="chart-4"/>
                    </div>

                    <div className='text-center'>
                        <Acquisition description={"100 Plots"} title={"Plots 2023"} data={acquisition}
                                     className="chart-4"/>
                    </div>
                    <div className='text-center'>
                        <DeviceSessions description={"60,000 Tokens"} title="Token Distribution " data={deviceSessions}
                                        className="chart-4"/>
                    </div>
                </div>
            </WidgetCard>
            <div className={"d-flex my-3 px-3 border-0 align-items-center"}>
                <h1>Plot: {plotId}</h1>
                {plotData ? <small className={'ml-3'}>{plotData?.txid}</small> : null}
            </div>
            <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3'}>
                {documents.length ?
                    <div><QuickAccess stream="plot" withIcons={true} title="Plot Documents" id={plotId}
                                      files={documents}/></div> : <></>}
            </WidgetCard>
            <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3'}>
                {plotEvents && plotData && plotId ?
                    <HistoryStory isField={true} events={plotEvents} data={plotData} documents={eventDocuments}/> : <></>}
            </WidgetCard>
        </div>
    )
}

export default SinglePlotDashboard;